.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .right {
    display: flex;
    gap: 10px;
  }
}
