.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcf7f4;
}

.form-container {
  width: 100%;
  max-width: 480px;
  padding: 20px;
}

.form-container {
  width: 100%;
}

.card {
  width: 540px;
  margin: 0 20px;
  max-width: 80%;
  border-radius: 10px !important;
}

.logo {
  margin-bottom: 200px;
  margin: 0 auto 80px;
  width: 180px;
  display: block;
}
